import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isNil } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable, from, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { jsonParseSafe } from 'src/app/utils/global.utils';
import { ApiService } from '../api/api.service';
import { StripeService } from './stripe.service';

/**
 * If user is not subscribed, routes user back to the auth page when visiting a page
 * with this Guard enabled.
 */
@Injectable({ providedIn: 'root' })
export class SubscriptionGuard implements CanActivate {
  constructor(
    private router: Router,
    // private authService: AuthService,
    private stripeService: StripeService,
    private apiService: ApiService,
    private logger: NGXLogger,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const data = route.queryParams?.accessId;
    const accessId = data && jsonParseSafe<{ id: string }>(atob(data))?.id;

    /**
     * Access ID check if there is access ID provided in the route query param (for agent)
     */
    if (!isNil(accessId) && typeof accessId === 'string') {
      this.logger.debug('Provided access ID: ', accessId);
      return this.apiService.validateAccess(accessId).pipe(
        tap(authorized => {
          if (!authorized) {
            this.router.navigate(['/auth']);
          }
        }),
      );
    }

    return from(this.stripeService.verifyStripeSubscriptionStatus()).pipe(
      map(status => {
        if (!status.isSubscribed) {
          this.router.navigateByUrl('/auth');

          // this.alertController
          //   .create({
          //     header: 'No longer subscribed',
          //     message: 'You need to be subscribed to use the app. Please renew your subscription or make a new one.',
          //     buttons: [
          //       {
          //         text: 'OK',
          //         handler: async () => {
          //           if (status.hasStripeCustomerId) {
          //             // Returning customer; send them to the Stripe portal if available.
          //             const stripeCustomerPortalUrl = await this.stripeService.createPortalSession();
          //             await Browser.open({
          //               url: stripeCustomerPortalUrl,
          //               toolbarColor: '#31406D', // Ionic primary colour
          //               windowName: '_blank',
          //             });
          //           } else {
          //             // New customer; send them to the new payment form.
          //             this.router.navigate(['/payment']);
          //           }
          //         },
          //       },
          //     ],
          //   })
          //   .then(alert => {
          //     alert.present();
          //     alert.onWillDismiss().then(() => {
          //       this.router.navigate(['/auth']);
          //     });
          //   });
        }
        return status.isSubscribed;
      }),
    );
  }
}
