/**
 * Creates a random number with the number of digits equalling the specified length.
 *
 * @example
 * console.log(randomNumberOfLength(8));
 * // => 48753894
 */
export function randomNumberOfLength(length: number) {
  const minimumNumberOfLength = 10 ** (length - 1);
  return Math.floor(Math.random() * (10 ** length - minimumNumberOfLength) + 1) + minimumNumberOfLength - 1;
}

export function sleep(ms = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 1000);
  });
}
