import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { Subject } from 'rxjs';
import { ICreateConversation } from 'src/app/types/conversation.type';
import { ApiService } from '../api/api.service';
import { ConversationStore } from './conversation.store';
import { forkJoin } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  triggerRefresh$ = new Subject();

  constructor(private apiService: ApiService, private conversationStore: ConversationStore) {}

  addConversation(jobId: number, input: ICreateConversation) {
    return this.apiService.addConversation(jobId, input).pipe(
      catchError(err => {
        this.conversationStore.setError(err);
        throw err;
      }),
    );
  }

  getConversationGroups(jobId: number) {
    return this.apiService.getConversationGroups(jobId).pipe(
      tap(conversationGroups => this.conversationStore.update({ conversationGroups })),
      catchError(err => {
        this.conversationStore.setError(err);
        throw err;
      }),
    );
  }

  getConversations(jobId: number, groupId: string) {
    return this.apiService.getConversations(jobId, groupId).pipe(
      tap(conversations => {
        const conversationOrdered = orderBy(conversations, ['createdAt'], ['asc']);
        this.conversationStore.update({ conversations: conversationOrdered });
      }),
      catchError(err => {
        this.conversationStore.setError(err);
        throw err;
      }),
    );
  }

  getConversation(jobId: number, conversationId: number) {
    return this.apiService.getConversation(jobId, conversationId).pipe(
      tap(conversation => this.conversationStore.update({ conversation })),
      catchError(err => {
        this.conversationStore.setError(err);
        throw err;
      }),
    );
  }

  deleteConversation(conversationId: number){
    return this.apiService.deleteConversation(conversationId);
  }

  uploadConversationAttachment(attachments: File[]) {
    this.conversationStore.setLoading(true);
    this.conversationStore.update({ uploading: true });
    return forkJoin(attachments.map(file => this.apiService.getUploadUrlv2(file, 'CONVERSATION_ATTACHMENT'))).pipe(
          map(results => ({ results, files: attachments })),
      switchMap(({ results, files }) =>
        forkJoin(results.map((r, i) => this.apiService.uploadFile(r.url, files[i]))).pipe(
          map(_ => ({ results, files })),
        ),
      ),
      map(r => r.results.map(f => f.s3Url) as string[]),
      catchError(err => {
        this.conversationStore.setError(err);
        throw err;
      }),
    );
  }
}
