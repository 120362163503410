import { ShippingTerm } from './job.type';
import { CartonDimension, CartonGroup, FclContainer } from './quote.type';

export enum CargoTypeCode {
  FCL = 'fcl',
  LCL = 'lcl',
  ROLL_ON_OFF = 'rollonoff',
  AIRFREIGHT = 'airfreight',
}
export interface FreightCalculationResult {
  result: ICalculationResult;
  taxResult?: TaxCalculationResult;
}

export interface TaxCalculationResult {
  exchangeRate?: number;
  insCost: number;
  cifCost: number;
  ipcCost: number;
  gst: number;
  duty?: number;
  totalApproxCustoms: number;
  totalFreightCost: number;
  totalApproxLandedCost: number;
  totalItem: number;
  totalLandedCost: number;
  totalCostPerItem: number;
  totalCostPerItemLessCustoms: number;
}

export interface ExchangeRate {
  id: number;
  code: string;
  rates: number;
  createdDateTime: string;
  lastModified: string;
}

export interface ICartonGroupRawResult {
  purchaseValue: number;
  exchangeRate: ExchangeRate;
  commercialValueInformation: any;
  cartonCBM: number;
  actualWeight: number;
  numOfCartons: number;
}

export type ICartonGroupResult = CartonGroup & ICartonGroupRawResult;

export interface ICalculationResult {
  cartonGroupResults: ICartonGroupResult[];
  orderQty?: number;
  // costPerPce?: number;
  commercialValueInformation?: { value: number; inferred: boolean };
  purchaseValue?: number;
  commercialValueCurrencyId?: number;
  dutyOrGst?: number;
  numOfCartons?: number;
  actualWeight?: number;
  totalCBM?: number;
  freightCost: number;
  freightCostAUD: number;
  freightRate: number;
  arrivalCost: number;
  arrivalRate: number;
  deliveryOrderFee: number;
  deliveryRate: number;
  cmrCost: number;
  cmrRate: number;
  attCustomsCost: number;
  attCustomsRate: number;
  cartageCost: number;
  cartageRate: number;
  termHandlingCost?: number;
  termHandlingRate?: number;
  subTotal: number;
  // Below values are not returned in LCLCalculation result
  deliveryCoordinationCost?: number;
  dutyApplicable?: boolean;
  originCost?: number;
  cartagePortCost?: number;
  bafCost?: number;
  pfsCost?: number;
  fumigationCost?: number;
  fumigationCertCost?: number;
  shippingTerm: ShippingTerm;
}

export interface AirFreightCalculatorInput {
  commodity: string;
  supplier: string;
  cartonDimensions: CartonDimension[];
  originPort?: Port;
  destinationPort?: Port;
  cartonWeight: number;
  cartonCapacity: number;
  expiryDate: string;
  dutyApplicable: boolean;
  cartonGroups: CartonGroup[];
  shippingTerm: string;
}

export interface RollOnOffCalculatorInput {
  commodity: string;
  cartonDimensions: CartonDimension[];
  cartonGroups: CartonGroup[];
  originPort?: Port;
  destinationPort?: Port;
  supplier: string;
  expiryDate: string;
  dutyApplicable: boolean;
  shippingTerm: string;
}

export interface FCLCalculatorInput {
  commodity: string;
  supplier: string;
  cartonGroups: CartonGroup[];
  originPort?: Port;
  destinationPort?: Port;
  containers: FclContainer[];
  shippingTerm: string;
  departureDate: string | Date;
  expiryDate: string | Date;
}

export interface LCLCalculatorInput {
  commodity: string;
  supplier: string;
  cartonGroups: CartonGroup[];
  originPort?: Port;
  destinationPort?: Port;
  dutyApplicable: boolean;
  shippingTerm: string;
  totalCartonGroupCBM: number;
  totalCartonGroupWeight: number;
  totalDimensionsInputOption: 'equivalent' | 'different';
  departureDate: string | Date;
  expiryDate: string | Date;
}

export interface BookingInput {
  companyName: string;
  personName: string;
  email: string;
  supplierInformation: string;
  extraInformation?: string;
  quoteType: 'Import' | 'Export';
  transportType: 'sea' | 'air';
  /**
   * Name of the location of the goods pickup (e.g. the origin port)
   * written as a string human-readably.
   */
  pickup: string;
  /**
   * Name of the location of the goods delivery (e.g. the destination port)
   * written as a string human-readably.
   */
  delivery: string;
  totalCBM: number;
  totalWeight: number;
  shipmentValue: number;
  shipmentValueCurrencyId: number;
  shipmentValueString: string;
}

export interface POARequestInput {
  companyName: string;
  personName: string;
  email: string;
  extraInformation?: string;
  quoteType: 'Import' | 'Export';
  transportType: 'sea' | 'air';
  /**
   * Name of the location of the goods pickup (e.g. the origin port)
   * written as a string human-readably.
   */
  pickup: string;
  /**
   * Name of the location of the goods delivery (e.g. the destination port)
   * written as a string human-readably.
   */
  delivery: string;
  totalCBM: number;
  totalWeight: number;
  shipmentValue: number;
  shipmentValueCurrencyId: number;
  shipmentValueString: string;
}

export interface FreightCalculatorRequestBody {
  type: CargoTypeCode;
  input: FreightCalculatorInput;
}

export interface Port {
  id: number;
  name: string;
  country: string;
  callsign?: string;
  address?: string;
  suburb?: string;
  // id: number;
  // name: string;
  // countryId: number;
  // countryName: string;
}

export enum DimensionType {
  width = 'width',
  height = 'height',
  length = 'length',
}

export interface Currency {
  id: number;
  name: string;
  code: string;
  symbol?: string;  // Should default to '$'
}

export interface Client {
  id: number;
  name: string;
}

export interface Vendor {
  id: number;
  name: string;
  type?: 'cartage' | 'lcl' | 'fcl';
  address?: string;
  suburb?: string;
  state?: string;
  country?: string;
  payment_terms?: string;
  importer_classname?: string;
}

export type FreightCalculatorInput =
  | LCLCalculatorInput
  | FCLCalculatorInput
  | AirFreightCalculatorInput
  | RollOnOffCalculatorInput;

export interface ContainerApprox {
  cartonLength?: number;
  cartonWidth?: number;
  cartonHeight?: number;
  cartonWeight: number;
  cartonCapacity: number;
  totalCartonGroupCBM?: number;
  totalCartonGroupWeight?: number;
  orderQty: number;
  groupDimensionsInputOption: 'total' | 'specific';
}

export interface ISupplier {
  id: number;
  companyName: string;
  contactName: string;
  email: string;
  phone: number;
  address: string;
  state: string;
  suburb: string;
  postCode: number;
  country: string;
  commodities: string[];
}

export interface Job {
  quoteId: number;
}
