/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, sum } from 'lodash';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ContainerApprox } from '../types/global.types';
import { CreateOrEditQuote } from '../types/quote.type';
import { FileSystemFileEntry } from 'ngx-file-drop';

export function jsonParseSafe<T>(input: string): T {
  try {
    return JSON.parse(input);
  } catch {
    return null;
  }
}

export function formatCurrency(input: number) {
  return `$${input.toFixed(2)}`;
}

export function getField(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig {
  for (let i = 0, len = fields.length; i < len; i++) {
    if (fields[i].key === key) {
      return fields[i];
    }

    if (fields[i].fieldGroup && fields[i].fieldGroup.length > 0) {
      return getField(fields[i].fieldGroup, key);
    }
  }
}

export function getCargoTypeDisplayName(cargoTypeCode: string) {
  if (cargoTypeCode === 'lcl') {
    return 'LCL';
  } else if (cargoTypeCode === 'fcl') {
    return 'FCL';
  } else if (cargoTypeCode === 'airfreight') {
    return 'Airfreight';
  } else if (cargoTypeCode === 'rollonoff') {
    return 'Roll on/off';
  }
  return 'Unrecognised Cargo Type';
}

export function getTotalCBM(quote: CreateOrEditQuote): number {
  // Currently unsure if we should treat the total CBM as the sum
  //   of all carton group CBMs or if they should be treated as
  //   'options' with the customer potentially using just one
  //   for their order. One potential solution is for the user
  //   to have a dropdown to select the wanted carton group(s),
  //   whereafter the CBM, weight and shipment value fields are
  //   autofilled to that result.
  return null;
  let totalCBM = 0;
  if (quote.totalDimensionsInputOption === 'different') {
    quote.cartonGroups.forEach(cartonGroup => {
      totalCBM +=
        cartonGroup.groupDimensionsInputOption === 'specific'
          ? (cartonGroup.cartonLength / 100) * (cartonGroup.cartonWidth / 100) * (cartonGroup.cartonHeight / 100)
          : // Multiply by number of cartons?
            cartonGroup.totalCartonGroupCBM;
    });
  } else {
    totalCBM +=
      quote.cartonGroups.length *
      (quote.groupDimensionsInputOption === 'specific'
        ? (quote.cartonLength / 100) * (quote.cartonWidth / 100) * (quote.cartonHeight / 100)
        : quote.totalCartonGroupCBM);
    return totalCBM;
  }
}

export function getTotalWeight(quote: CreateOrEditQuote): number {
  // Same comments as with getTotalCBM
  return null;
  const totalWeight = 0;
}

export function getTotalShipmentValue(quote: any): number {
  // Same comments as with getTotalCBM
  return null;
  return quote.cartonGroups[0].costPerPce * quote.cartonGroups[0].orderQty;
}

export enum errorStatus {
  NEUTRAL = 0,
  ERROR = 1,
  SUCCESS = -1,
}

export function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export const getSuggestedContainers = (
  params: ContainerApprox,
  containerTypes = [
    {
      id: 1,
      name: '20\'',
      maxCBM: 33,
      maxLength: 590,
      maxWidth: 230,
      maxHeight: 239,
      maxWeight: 21770,
    },
    {
      id: 2,
      name: '40\'',
      maxCBM: 66,
      maxLength: 1203,
      maxWidth: 235,
      maxHeight: 239,
      maxWeight: 26780,
    },
  ],
) => {
  // Unpack value
  const {
    cartonLength,
    cartonWidth,
    cartonHeight,
    cartonWeight,
    cartonCapacity,
    totalCartonGroupCBM,
    totalCartonGroupWeight,
    orderQty,
    groupDimensionsInputOption,
  } = params;

  if (groupDimensionsInputOption === 'specific' && !orderQty) {
    throw Error('You must enter a value for Order Quantity under "Carton Groups" above.');
  }

  const cartonQuantity = orderQty ? orderQty / cartonCapacity : cartonCapacity;

  // Calculate Total CBM
  const totalCBM =
    groupDimensionsInputOption === 'total'
      ? totalCartonGroupCBM
      : (cartonLength / 100) * (cartonWidth / 100) * (cartonHeight / 100) * cartonQuantity;
  const totalWeight = groupDimensionsInputOption === 'total' ? totalCartonGroupWeight : cartonWeight * cartonQuantity;

  const containers = cloneDeep(containerTypes).map((container: any) => {
    container.quantity = 0;
    return container;
  });

  // Twenty and forty foot containers
  const twentyFootContainer = containers.find(container => container.name === '20\'');
  const fortyFootContainer = containers.find(container => container.name === '40\'');
  const {
    maxLength: twentyFootLength,
    maxWidth: twentyFootWidth,
    maxHeight: twentyFootHeight,
    maxCBM: twentyFootCBM,
    maxWeight: twentyFootWeight,
  } = twentyFootContainer;
  const { maxWeight: fortyFootWeight } = fortyFootContainer;

  // Calculate minimum 20'
  const minimumContainers = Math.ceil(totalCBM / twentyFootCBM);

  // Even number of 20' containers
  if (minimumContainers % 2 === 0) {
    fortyFootContainer.quantity = minimumContainers / 2;
  } else {
    // Odd number containers
    const numOfContainers = minimumContainers - 1;
    fortyFootContainer.quantity = numOfContainers / 2;
    twentyFootContainer.quantity = 1;
  }

  // Check that the input carton length does not exceed 20' container length
  // This is temporary; a better solution that takes into account the number
  //   of cartons will be necessary.
  if (cartonLength > twentyFootLength && fortyFootContainer.quantity === 0) {
    // Use one forty foot container in place of the exceeded twenty foot container
    fortyFootContainer.quantity = 1;
    twentyFootContainer.quantity--;
  }

  const totalContainerWeight = Math.round(
    fortyFootContainer.quantity * fortyFootWeight + twentyFootContainer.quantity * twentyFootWeight,
  );

  if (totalWeight > totalContainerWeight) {
    throw Error(`Total Weight must not exceed ${totalContainerWeight} kg`);
  }

  return containers;
};

export const buildAlertError = (error: Error, title: string) => ({
  // cssClass: 'alert',
  header: title,
  message: `${error?.message}`,
  buttons: [{ text: 'Ok' }],
});

export function parseErrorMsg(error: any) {
  return error?.error?.error || error?.error?.message || error?.message || error;
}

export function getFile(fileDropEntry: NgxFileDropEntry): Promise<File> {
  const fileEntry = fileDropEntry.fileEntry as FileSystemFileEntry;
  return new Promise((resolve, reject) => {
    fileEntry.file(file => {
      resolve(file);
    });
  });
}
