import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isNil } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { jsonParseSafe } from 'src/app/utils/global.utils';
import { ApiService } from '../api/api.service';
import { AuthService } from './auth.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private logger: NGXLogger,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const data = route.queryParams?.accessId;
    const accessId = data && jsonParseSafe<{ id: string }>(atob(data))?.id;

    /**
     * Access ID check if there is access ID provided in the route query param (for agent)
     */
    if (!isNil(accessId) && typeof accessId === 'string') {
      this.logger.debug('Provided access ID: ', accessId);
      return this.apiService.validateAccess(accessId).pipe(
        tap(authorized => {
          if (!authorized) {
            this.router.navigate(['/auth']);
          }
        }),
      );
    }


    /**
     * Normal login
     */
    return this.authService.isAuthenticated().pipe(
      switchMap(loggedIn =>
        this.authService.getCognitoGroups().pipe(
          map(cognitoGroups => {
            const group = route.data.group;
            const userInGroup = Boolean(!group || (group && group === 'Admin' && cognitoGroups.indexOf(group) >= 0));

            return loggedIn && userInGroup;
          }),
        ),
      ),
      tap(authorized => {
        if (!authorized) {
          this.router.navigate(['/auth']);
        }
      }),
    );
  }
}
