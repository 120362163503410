import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { NGXLogger } from 'ngx-logger';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AlertController, ToastController } from '@ionic/angular';
import { MakePaymentParams } from 'src/app/types/payment.types';
import { sleep } from 'src/app/utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public readonly subscribed$: Observable<boolean>;
  private readonly _subscribed: BehaviorSubject<boolean>;

  constructor(
    private api: ApiService,
    private alertController: AlertController,
    private toastController: ToastController,
    private logger: NGXLogger,
  ) {
    this._subscribed = new BehaviorSubject<boolean>(false);
    this.subscribed$ = this._subscribed.asObservable();
  }

  async verifyStripeSubscriptionStatus(): Promise<{
    /** Indicates whether the user has a subscription in `active` or `trialing` status. */
    isSubscribed: boolean;
    /** Indicates whether the user has the `stripeId` Cognito attribute, indicating they are a prior customer. */
    hasStripeCustomerId: boolean;
    /** Indicates whether the user has been appointed as a shipping agent/vendor. */
    isVendor: boolean;
  }> {
    const attributes = await Auth.userAttributes(await Auth.currentAuthenticatedUser());
    const subscribed = attributes.find(attribute => attribute.Name === 'custom:subscribed')?.Value === 'true';
    const stripeCustomerId = attributes.find(attribute => attribute.Name === 'custom:stripeId')?.Value;
    const isVendor = attributes.find(attribute => attribute.Name === 'custom:role')?.Value === 'shipping_agent';
    this._subscribed.next(subscribed);
    return { isSubscribed: subscribed, hasStripeCustomerId: !!stripeCustomerId?.length, isVendor };
  }

  async createPortalSession(): Promise<string> {
    const response = await this.api.createStripePortalSession().toPromise();
    if (response.success) {
      const toastId = 'mvff-stripe-customer-portal-open-toast';
      if (!document.getElementById(toastId)) {
        await this.toastController
          .create({
            id: toastId,
            header: 'Opened Customer Portal',
            message:
              'If you cannot see it, please ensure that popups for this website are not blocked. (Click to dismiss)',
            duration: 5000,
            position: 'middle',
          })
          .then(async toast => {
            await toast.present();
          });

        const createdToastElement = document.getElementById(toastId);
        // Sometimes, the toast gets stuck on screen, so we'll use this event listener
        // so that the user can click to dismiss it.
        createdToastElement?.addEventListener('click', event => {
          const eventTarget = event.target as HTMLElement;
          eventTarget.parentElement.removeChild(eventTarget);
        });
        createdToastElement.title = 'Click to dismiss';
        createdToastElement.style.cursor = 'pointer';
      }
      return response.billingPortalSessionUrl;
    }
    throw Error('Request to create Stripe portal session returned success = false.');
  }

  async getStripePublishableKey() {
    return await this.api.getStripePublishableKey().toPromise();
  }

  async getStripeProductInformation() {
    return await this.api.getStripeProductInformation().toPromise();
  }

  async processStripePayment(params: MakePaymentParams) {
    return await this.api.makeStripePayment(params).toPromise();
  }

  async getUserPaymentCard() {
    return await this.api.getUserPaymentCard().toPromise();
  }

  async removeUserPaymentCard() {
    return await this.api.removeUserPaymentCard().toPromise();
  }

  async cancelStripeSubscription() {
    return await this.api.cancelStripeSubscription().toPromise();
  }

  async renewSubscription() {
    return await this.api.renewStripeSubscription().toPromise();
  }

  /**
   * This function should be used if you expect the user to have their Cognito
   * `custom_subscribed` attribute to soon be `'true'`, and it is not currently.
   * It will resolve when the Cognito attribute becomes `'true'`. This currently
   * is used for checking when the Stripe webhook updates the Cognito attribute,
   * because this action happens asynchronously to other backend functions like
   * completing or renewing a subscription, so the Cognito attributes that are
   * set immediately after those functions complete are not fully reliable.
   */
  async waitForSubscriptionStatusComplete(maxAttempts = 10, showAlertIfError = true) {
    let userHasSubscribedAttribute = false;
    let attemptCounter = 0;
    const MAX_ATTEMPTS = maxAttempts;
    do {
      // TODO: Test for this scenario?
      if (++attemptCounter >= MAX_ATTEMPTS) {
        if (showAlertIfError) {
          this.alertController
            .create({
              header: 'Loading timed out',
              message: `Sorry, there was an issue with your account after subscribing. Please try to
               sign in again. If you appear to not be subscribed, please get in touch with us.`,
            })
            .then(alert => {
              alert.present();
            });
        }
        throw Error('Too many attempts for checking subscription status.');
        break;
      }
      await sleep(2000);
      await this.verifyStripeSubscriptionStatus().then(status => {
        userHasSubscribedAttribute = status.isSubscribed;
      });
    } while (!userHasSubscribedAttribute);
  }
}
