import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Conversation, IConversation, IConversationGroup, IListConversation } from 'src/app/types/conversation.type';

export interface ConversationState {
  conversationGroups: IConversationGroup[];
  conversations: Conversation[];
  conversation: Conversation;
  currentConversationId: number;
  uploading: boolean;
}

function createInitialState(): ConversationState {
  return {
    conversationGroups: null,
    conversations: null,
    conversation: null,
    currentConversationId: null,
    uploading: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'conversation', resettable: true })
export class ConversationStore extends Store<ConversationState> {
  constructor() {
    super(createInitialState());
  }
}
