import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { APP_MENU, ADMIN_MENU_ITEMS, AppMenu, SHIPPING_AGENT_MENU_ITEMS } from './app.menu';
import { AuthService } from './services/auth/auth.service';
import { ApiService } from './services/api/api.service';
import { Router } from '@angular/router';
import { AlertController, MenuController, ToastController } from '@ionic/angular';
import { WebsocketService } from './services/websocket.service';
import { ConversationService } from './services/conversation/conversation.service';
import { StripeService } from './services/stripe/stripe.service';
import { concat } from 'lodash';
import { AuthStore } from './services/auth/auth.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn$ = this.authService.loggedIn$;
  subscribed$ = this.stripeService.subscribed$;
  appMenu = APP_MENU;
  allMenu: AppMenu[];
  get currentRoute() {
    return this.router.url;
  }

  constructor(
    private logger: NGXLogger,
    public authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private menuController: MenuController,
    private wsService: WebsocketService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private conversationService: ConversationService,
    private stripeService: StripeService,
  ) {
    this.initWebSocket();
  }

  ngOnInit() {
    this.loggedIn$.subscribe(async loggedIn => {
      if (loggedIn) {
        const userStatus = await this.authService.getOrSetUserStatus();
        if (userStatus.isAdmin) {
          this.allMenu = [...APP_MENU, ...ADMIN_MENU_ITEMS];
          this.logger.debug('allMenu', this.allMenu);
          this.logger.debug('ADMIN_MENU_ITEMS', ADMIN_MENU_ITEMS);
        } else if (userStatus.isVendor) {
          this.allMenu = SHIPPING_AGENT_MENU_ITEMS;
        } else {
          this.allMenu = APP_MENU;
        }
      } else {
        this.authService.unsetUserStatus();
      }
    });
  }

  async onItemClick(menuItem: AppMenu) {
    if (typeof menuItem.preRouteHandler === 'function') {
      await menuItem.preRouteHandler(this);
    }
    if (menuItem.url) {
      this.router.navigate([menuItem.url]);
    }
    if (typeof menuItem.postRouteHandler === 'function') {
      await menuItem.postRouteHandler(this);
    }
    this.menuController.close();
  }

  initWebSocket() {
    this.logger.log('initWebSocket');
    this.wsService.connect();

    this.wsService.docParsedEvent().subscribe(async event => {
      const data = event.data;

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Bill of Lading Updated',
        // subHeader: '',
        // eslint-disable-next-line max-len
        message: `Our system detected the document that you uploaded has bill of lading number of "${data?.trackingNumber}".
        We have updated the bill of lading automatically.`,
        buttons: ['Ok'],
      });
      await alert.present();
    });

    this.wsService.newMessageEvent().subscribe(async event => {
      const data = event.data;

      if (data.from) {
        const syncToast = await this.toastCtrl.create({
          message: `You have a new message from ${data.from}.`,
          duration: 5000,
        });
        await syncToast.present();

        this.conversationService.triggerRefresh$.next();
      }
    });
  }
}
