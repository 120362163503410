import { Component } from '@angular/core';
import { AppComponent } from './app.component';

export interface AppMenu {
  title: string;
  url?: string;
  icon: string;
  preRouteHandler?: (appComponent: AppComponent) => Promise<void> | void;
  postRouteHandler?: (appComponent: AppComponent) => Promise<void> | void;
}

export const APP_MENU: AppMenu[] = [
  {
    title: 'Dashboard',
    url: '/tabs/home',
    // iconSrc: 'assets/icon/icon-compass-navy.svg',
    icon: 'compass-outline',
  },
  {
    title: 'LCL Calculator',
    url: '/tabs/lcl-calculator',
    // iconSrc: 'assets/icon/icon-binder-navy.svg',
    icon: 'calculator-outline',
  },
  {
    title: 'FCL Calculator',
    url: '/tabs/fcl-calculator',
    // iconSrc: 'assets/icon/icon-extract-navy.svg',
    icon: 'pricetag-outline',
  },
  {
    title: 'My Quotes',
    url: '/quote-list-all',
    // iconSrc: 'assets/icon/icon-extract-navy.svg',
    icon: 'bookmark-outline',
  },
  {
    title: 'My Jobs',
    url: '/job-list',
    // iconSrc: 'assets/icon/icon-extract-navy.svg',
    icon: 'bag-outline',
  },
  {
    title: 'My Suppliers',
    url: '/suppliers',
    // iconSrc: 'assets/icon/icon-extract-navy.svg',
    icon: 'people-outline',
  },
  // {
  //   title: 'Roll on/off Calculator',
  //   url: '/tabs/roll-on-off-calculator',
  //   // iconSrc: 'assets/icon/icon-extract-navy.svg',
  //   icon: 'hammer-outline',
  // },
  // {
  //   title: 'Airfreight',
  //   url: '/tabs/airfreight-calculator',
  //   // iconSrc: 'assets/icon/icon-extract-navy.svg',
  //   icon: 'airplane-outline',
  // },
  {
    title: 'My profile',
    url: '/tabs/profile',
    // iconSrc: 'assets/icon/icon-user-navy.svg',
    icon: 'people-circle-outline',
  },
];

export const ADMIN_MENU_ITEMS = [
  {
    title: 'Insert Rates',
    url: '/tabs/insert-rates',
    icon: 'arrow-up-circle-outline',
  },
  {
    title: 'Shipping Agents',
    url: '/tabs/shipping-agents',
    icon: 'boat-outline',
  },
];

export const SHIPPING_AGENT_MENU_ITEMS = [
  {
    title: 'Sign Out',
    icon: 'arrow-back-outline',
    preRouteHandler: async (appComponent: AppComponent) => {
      await appComponent.authService.signOut();
    },
  },
];
