import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { isNil } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { jsonParseSafe } from 'src/app/utils/global.utils';
import { AuthService } from '../auth/auth.service';
import { InterceptorSkipHeader } from './enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private route: ActivatedRoute, private logger: NGXLogger) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add access id to the header for agent side
    const data = this.route.snapshot.queryParams?.accessId;
    const accessId = data && jsonParseSafe<{ id: string }>(atob(data))?.id;
    if (!isNil(accessId) && typeof accessId === 'string') {
      req = req.clone({
        headers: req.headers
          .delete(InterceptorSkipHeader.Authoriztaion)
          .delete('Authorization')
          .set('Accept', 'application/json')
          .set('Authorization', accessId),
      });
      return next.handle(req);
    }

    if (req.headers.has(InterceptorSkipHeader.Authoriztaion)) {
      req = req.clone({
        headers: req.headers.delete(InterceptorSkipHeader.Authoriztaion),
      });
      req = req.clone({
        headers: req.headers.set('Accept', 'application/json'),
      });
      return next.handle(req);
    }
    return from(Auth.currentSession()).pipe(
      switchMap(session => {
        if (!req.headers.has(InterceptorSkipHeader.Authoriztaion)) {
          const token = session.getIdToken().getJwtToken();
          if (token) {
            req = req.clone({
              headers: req.headers.set('Authorization', 'Bearer ' + token),
            });
          }
        } else {
          req = req.clone({
            headers: req.headers.delete(InterceptorSkipHeader.Authoriztaion),
          });
        }
        if (!req.headers.has('Content-Type')) {
          req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json'),
          });
        }
        req = req.clone({
          headers: req.headers.set('Accept', 'application/json'),
        });
        return next.handle(req);
      }),
    );
  }
}
