import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';

export const interceptorProviders = [
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: CustomHeaderInterceptor,
  //   multi: true,
  // },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
