import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import * as querystring from 'querystring';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ICartage } from 'src/app/types/cartage.type';
import {
  Conversation,
  IConversationGroup,
  ICreateConversation,
  SendEmailBodyRequest,
} from 'src/app/types/conversation.type';
import { EmailTemplate } from 'src/app/types/email.type';
import {
  CargoTypeCode,
  FreightCalculationResult,
  FreightCalculatorInput,
  FreightCalculatorRequestBody,
  ISupplier,
  Port,
  Vendor,
} from 'src/app/types/global.types';
import {
  ContainerNumber,
  ContainerType,
  IContainer,
  IContainerNumber,
  IContainerSummary,
  IJob,
  IJobActivity,
  IJobSummary,
  IListJob,
  IUploadedFile,
  JobStage,
} from 'src/app/types/job.type';
import { AddNote, INotes } from 'src/app/types/note.types';
import { IPartnerOffice } from 'src/app/types/partner.type';
import { GetProductInformationResponse, MakePaymentParams, PaymentCardInfo } from 'src/app/types/payment.types';
import { environment } from 'src/environments/environment';
import { CreateOrEditQuote, Quote } from '../../types/quote.type';
import { IUpdateUserDetail, IUpdateUserDetailApi } from '../auth/auth.service';
import { InterceptorSkipHeader } from '../interceptors/enum';
import {
  CreateShippingAgentResponse,
  UpdateShippingAgentResponse,
  GetShippingAgentsResponse,
  ShippingAgentFormInput,
} from 'src/app/types/shipping-agents.types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  svcApiUrlA: string;

  constructor(private logger: NGXLogger, private http: HttpClient) {
    this.svcApiUrlA = environment.apiBaseUrl;
  }

  getContainerTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.svcApiUrlA + 'containerTypes').pipe(map((result: any) => result?.data));
  }

  getRateTypes(): Observable<string[]> {
    return this.http
      .get<{ success: boolean; rateTypes: string[] }>(this.svcApiUrlA + 'rateTypes')
      .pipe(map(result => result?.rateTypes));
  }

  getPorts(
    query?: string,
    params?: Partial<{
      countryId: number;
      cargoType: CargoTypeCode;
      loadingPortId: number;
    }>,
  ): Observable<Port[]> {
    let url = this.svcApiUrlA + 'ports';

    if (query || (typeof params === 'object' && Object.keys(params).length)) {
      const queryParams = querystring.stringify(omitBy({ ...params, searchQuery: query }, isNil));
      url = url + '?' + queryParams;
    }

    return this.http.get<Port[]>(url).pipe(map((result: any) => result?.data));
  }

  getClients(query?: string): Observable<any[]> {
    const params = {
      ...(query ? { searchQuery: query } : {}),
    };
    let url = this.svcApiUrlA + 'clients';
    if (query) {
      url = `${url}?${querystring.stringify(params)}`;
    }
    return this.http.get<any[]>(url).pipe(map((result: any) => result?.data));
  }

  getSupplier(id: number) {
    const url = this.svcApiUrlA + 'suppliers/' + id;
    return this.http.get<any>(url).pipe(map((result: any) => result.supplier));
  }

  /**
   * @deprecated
   */
  getSuppliersV1(companyName?: string): Observable<any[]> {
    const queryParams = querystring.stringify({ companyName });
    const url = companyName ? this.svcApiUrlA + 'supplierList?' + queryParams : this.svcApiUrlA + 'supplierList';
    return this.http.get<any[]>(url).pipe(map((result: any) => result.suppliers));
  }

  createOrEditQuote(data: CreateOrEditQuote): Observable<number> {
    const url = this.svcApiUrlA + 'quotes';
    return this.http.post<any>(url, data).pipe(map(result => result?.data?.quoteId));
  }

  calculate(input: FreightCalculatorInput, type: CargoTypeCode): Observable<FreightCalculationResult> {
    const url = this.svcApiUrlA + 'calculate';
    return this.http.post<any>(url, { input, type } as FreightCalculatorRequestBody).pipe(map(result => result?.data));
  }

  calculateQuote(quoteId: number): Observable<FreightCalculationResult> {
    const url = this.svcApiUrlA + 'calculateQuote/' + quoteId;
    return this.http.get<any>(url).pipe(map(result => result?.data));
  }

  getQuotes(): Observable<Quote[]> {
    const url = this.svcApiUrlA + 'quotes';
    return this.http.get<any>(url).pipe(map(result => result?.data));
  }

  getQuote(id: number): Observable<Quote> {
    if (!id) {
      return null;
    }
    const url = this.svcApiUrlA + 'quotes/' + id;
    return this.http.get<any>(url).pipe(map(result => result?.data));
  }

  deleteQuote(id: number) {
    const url = this.svcApiUrlA + `quotes/${id}`;
    return this.http.delete<void>(url);
  }

  importRatesSpreadsheet(data: { rateType: string; vendorId: number; fileUrl: string }) {
    if (!data.rateType || !data.fileUrl) {
      return;
    }
    const url = this.svcApiUrlA + 'insertRates';
    return this.http.post<any>(url, data);
  }

  getVendors(): Observable<Vendor[]> {
    const url = this.svcApiUrlA + 'vendors';
    return this.http.get<Vendor[]>(url).pipe(map((result: any) => result.vendors));
  }

  getUploadUrl(fileName: string, mimeType: string) {
    const url = this.svcApiUrlA + 'getUploadUrl';
    return this.http.post<string>(url, { fileName, mimeType }).pipe(map((result: any) => result.uploadUrl));
  }

  getSignedUrlOfObject(key: string) {
    const url = this.svcApiUrlA + 'getSignedUrl';
    return this.http.post<any>(url, { key }).pipe(map((result: any) => result.signedUrl));
  }

  getAdminStatus() {
    const url = this.svcApiUrlA + 'adminStatus';
    return this.http.get(url).pipe(map((result: { isAdmin: boolean }) => result.isAdmin));
  }

  getUserStatus() {
    const url = this.svcApiUrlA + 'userStatus';
    return this.http.get(url).pipe(map((result: { isAdmin: boolean; isVendor: boolean }) => result));
  }

  createBooking(bookingInformation: any) {
    const url = this.svcApiUrlA + 'createBooking';
    return this.http.post<any>(url, { bookingInformation }).pipe(map((result: any) => result.emailResult));
  }

  createPOARequest(requestInformation: any) {
    const url = this.svcApiUrlA + 'createRequest';
    return this.http.post<any>(url, { requestInformation }).pipe(map((result: any) => result.emailResult));
  }

  getQuoteCalculationResult(quoteId: number) {
    const url = `${this.svcApiUrlA}quoteCalculationResults/${quoteId}`;
    return this.http.get<any>(url).pipe(map((result: any) => result.calculationResult));
  }

  getSingleRate(originPortId: number, destinationPortId: number, rateType: CargoTypeCode, containerTypeId?: number) {
    // Assume that 20' and 40' would have the same expiry dates, so getting expiry dates for FCL would be inconsequential
    const queryParams = querystring.stringify({
      originPortId,
      destinationPortId,
      rateType,
      containerTypeId: containerTypeId ?? (rateType === CargoTypeCode.FCL ? 1 : undefined),
    });
    const url = `${this.svcApiUrlA}singleRates?${queryParams}`;
    return this.http.get<any>(url).pipe(map((result: any) => result.rate));
  }

  getCurrencies() {
    const url = `${this.svcApiUrlA}currencies`;
    return this.http.get<any>(url).pipe(map((result: any) => result?.data));
  }

  getSuppliers() {
    const url = `${this.svcApiUrlA}supplier`;
    return this.http.get<{ success: boolean; data: ISupplier[] }>(url).pipe(map(result => result?.data));
  }

  updateSupplier(data: ISupplier) {
    const url = `${this.svcApiUrlA}supplier`;
    return this.http.put<void>(url, data);
  }

  createSupplier(data: ISupplier) {
    const url = `${this.svcApiUrlA}supplier`;
    return this.http.post<any>(url, data).pipe(map(result => result?.data?.supplierId));
  }

  deleteSupplier(supplierId: number) {
    const url = this.svcApiUrlA + `supplier`;
    return this.http.request<void>('delete', url, { body: { id: supplierId } });
  }

  initJob(quoteId: number) {
    const url = this.svcApiUrlA + `initJob`;
    return this.http.post<{ success: boolean; jobId: number }>(url, { quoteId }).pipe(map(result => result?.jobId));
  }

  getJob(jobId: number) {
    const url = this.svcApiUrlA + `job/${jobId}`;
    return this.http.get<{ success: boolean; data: IJob }>(url).pipe(map(result => result?.data));
  }

  getJobs() {
    const url = this.svcApiUrlA + `jobs`;
    return this.http.get<{ success: boolean; data: IListJob[] }>(url).pipe(map(result => result?.data));
  }

  updateJob(jobId: number, stage: JobStage, data: Partial<IJob>) {
    const url = this.svcApiUrlA + `updateJob`;
    return this.http.post<void>(url, { jobId, stage, data });
  }

  deleteJob(jobId: number) {
    const url = this.svcApiUrlA + `job`;
    return this.http.request<void>('delete', url, { body: { jobId } });
  }

  getUploadUrlv2(file: File, type: string) {
    const url = this.svcApiUrlA + 'getUploadUrlv2';
    return this.http
      .post<{ success: boolean; data: { url: string; s3Url: string } }>(url, {
        fileName: file.name,
        type,
        contentType: file.type,
      })
      .pipe(map(result => result?.data));
  }

  uploadFile(uploadUrl: string, file: File) {
    const params = new HttpParams();
    const headers = new HttpHeaders().set('Content-Type', file.type).set(InterceptorSkipHeader.Authoriztaion, '');

    const options = {
      params,
      reportProgress: true,
      headers,
    };

    const req = new HttpRequest('PUT', uploadUrl, file, options);
    return this.http.request<any>(req).pipe(map(event => ({ ...event, filename: file.name, contentType: file.type })));
  }

  addUploadedFile(input: IUploadedFile) {
    const url = this.svcApiUrlA + 'addFile';
    return this.http.post<{ success: boolean; fileId: number }>(url, input).pipe(map(result => result?.fileId));
  }

  addConversationAttachment(input: IUploadedFile) {
    const url = this.svcApiUrlA + 'addConversationAttachment';
    return this.http.post<{ success: boolean; fileId: number }>(url, input).pipe(map(result => result?.fileId));
  }

  deleteUploadedFile(fileId: number) {
    const url = this.svcApiUrlA + 'deleteFile';
    return this.http.request<void>('delete', url, { body: { fileId } });
  }

  getUploadedFiles(jobId: number) {
    const url = this.svcApiUrlA + `getFiles/${jobId}`;
    return this.http.get<{ success: boolean; data: IUploadedFile[] }>(url).pipe(map(result => result?.data));
  }

  downloadFromS3<T>(url: string) {
    const headers = new HttpHeaders()
      // .set('Content-Type', type)
      .set(InterceptorSkipHeader.Authoriztaion, '');

    return this.http.get<T>(url, { headers, responseType: 'text' } as Record<string, any>);
  }

  addConversation(jobId: number, input: ICreateConversation) {
    const url = this.svcApiUrlA + `conversation/${jobId}`;
    return this.http.post<{ success: boolean }>(url, input);
  }

  getConversationGroups(jobId: number) {
    const url = this.svcApiUrlA + `conversation/${jobId}`;
    return this.http.get<{ success: boolean; data: IConversationGroup[] }>(url).pipe(map(result => result?.data));
  }

  getConversations(jobId: number, groupId: string) {
    const url = this.svcApiUrlA + `conversation/${jobId}/${groupId}`;
    return this.http.get<{ success: boolean; data: Conversation[] }>(url).pipe(map(result => result?.data));
  }

  getConversation(jobId: number, conversationId: number) {
    const url = this.svcApiUrlA + `conversation/${jobId}/${conversationId}`;
    return this.http.get<{ success: boolean; data: Conversation }>(url).pipe(map(result => result?.data));
  }

  getJobActivity(jobId: number) {
    const url = this.svcApiUrlA + `jobActivity/${jobId}`;
    return this.http.get<{ success: boolean; data: IJobActivity[] }>(url).pipe(map(result => result?.data));
  }

  getEmailTemplates() {
    const url = this.svcApiUrlA + `getEmailTemplates`;
    return this.http.get<{ success: boolean; data: EmailTemplate[] }>(url).pipe(map(result => result?.data));
  }

  getPartnerOffices() {
    const url = this.svcApiUrlA + `partnerOffices`;
    return this.http.get<{ success: boolean; data: IPartnerOffice[] }>(url).pipe(map(result => result?.data));
  }

  getCartages() {
    const url = this.svcApiUrlA + `cartages`;
    return this.http.get<{ success: boolean; data: ICartage[] }>(url).pipe(map(result => result?.data));
  }

  getContainers(jobId: number) {
    const url = this.svcApiUrlA + `containers/${jobId}`;
    return this.http.get<{ success: boolean; data: IContainer[] }>(url).pipe(map(result => result?.data));
  }

  getContainerNumber(jobId: number) {
    const url = this.svcApiUrlA + `containerNumber/${jobId}`;
    return this.http.get<{ success: boolean; data: ContainerNumber[] }>(url).pipe(map(result => result?.data));
  }

  getContainerNumbers(jobId: number) {
    const url = this.svcApiUrlA + `getContainerNumbers/${jobId}`;
    return this.http.get<{ success: boolean; data: IContainerNumber[] }>(url).pipe(map(result => result?.data));
  }

  updateContainerNumber(data: IContainerNumber) {
    const url = `${this.svcApiUrlA}containerNumber`;
    return this.http.put<void>(url, data);
  }

  validateAccess(accessId: string) {
    const url = `${this.svcApiUrlA}validateAccess`;
    return this.http
      .post<{ success: boolean }>(
        url,
        { id: accessId },
        {
          headers: {
            [InterceptorSkipHeader.Authoriztaion]: InterceptorSkipHeader.Authoriztaion,
          },
        },
      )
      .pipe(map(result => result?.success));
  }

  addNote(jobId: number, input: AddNote) {
    const url = this.svcApiUrlA + `notes/${jobId}`;
    return this.http.post<{ success: boolean }>(url, input);
  }

  getNotes(jobId: number) {
    const url = this.svcApiUrlA + `notes/${jobId}`;
    return this.http.get<{ success: boolean; data: INotes[] }>(url).pipe(map(result => result?.data));
  }

  deleteNote(jobId: number, id: number) {
    const url = this.svcApiUrlA + `notes/${jobId}`;
    return this.http.request<void>('delete', url, { body: { id } });
  }

  deleteConversation(conversationId: number) {
    const url = this.svcApiUrlA + `conversation`;
    return this.http.request<void>('delete', url, { body: { conversationId } });
  }

  getJobSummary(jobId: number) {
    const url = this.svcApiUrlA + `getJobSummary/${jobId}`;
    return this.http.get<{ success: boolean; data: IJobSummary }>(url).pipe(map(result => result?.data));
  }

  sendEmail(jobId: number, data: SendEmailBodyRequest) {
    const url = this.svcApiUrlA + `email/${jobId}`;
    return this.http.post<{ success: boolean }>(url, data);
  }

  getContainerSummary(jobId: number) {
    const url = this.svcApiUrlA + `getContainerSummary/${jobId}`;
    return this.http.get<{ success: boolean; data: IContainerSummary[] }>(url).pipe(map(result => result?.data));
  }

  getContainerType() {
    const url = this.svcApiUrlA + `getContainerTypes`;
    return this.http.get<{ success: boolean; data: ContainerType[] }>(url).pipe(map(result => result?.data));
  }

  updateUserDetail(data: IUpdateUserDetailApi) {
    const url = this.svcApiUrlA + `accountSetting`;
    return this.http.put<{ success: boolean }>(url, data);
  }

  getUserDetail() {
    const url = this.svcApiUrlA + `accountSetting`;
    return this.http.get<{ success: boolean; data: any }>(url).pipe(map(result => result?.data));
  }

  createStripePortalSession() {
    this.logger.log('ApiService::createStripePortalSession()');
    const url = `${this.svcApiUrlA}payment/portal`;
    return this.http
      .post<{ success: boolean; billingPortalSessionUrl: string }>(url, { envDomain: environment.appBaseUrl })
      .pipe(
        catchError(error => {
          this.logger.error('Error creating Stripe customer portal:', error);
          throw error;
        }),
      );
  }

  getStripePublishableKey() {
    const url = `${this.svcApiUrlA}payment/key`;
    return this.http.get<{ success: boolean; key: string }>(url).pipe(
      catchError(error => {
        this.logger.error('Error getting Stripe publishable key:', error);
        throw error;
      }),
    );
  }

  getStripeProductInformation() {
    const url = `${this.svcApiUrlA}payment/product`;
    return this.http.get<GetProductInformationResponse>(url).pipe(
      catchError(error => {
        this.logger.error('Error getting Stripe product information:', error);
        throw error;
      }),
    );
  }

  makeStripePayment(paymentParams: MakePaymentParams) {
    const url = `${this.svcApiUrlA}payment`;
    return this.http.post<{ success: boolean }>(url, paymentParams).pipe(
      catchError(error => {
        this.logger.error('Error making Stripe payment:', error);
        throw error;
      }),
    );
  }

  getUserPaymentCard() {
    const url = `${this.svcApiUrlA}payment/card`;
    return this.http.get<{ success: boolean; card: PaymentCardInfo }>(url).pipe(
      map(response => response.card),
      catchError(error => {
        this.logger.error('Error getting user payment card:', error);
        throw error;
      }),
    );
  }

  removeUserPaymentCard() {
    const url = `${this.svcApiUrlA}payment/card`;
    return this.http.delete<{ success: boolean }>(url).pipe(
      catchError(error => {
        this.logger.error('Error removing user payment card:', error);
        throw error;
      }),
    );
  }

  cancelStripeSubscription() {
    const url = `${this.svcApiUrlA}payment/subscriptions`;
    return this.http.delete<{ success: boolean }>(url).pipe(
      catchError(error => {
        this.logger.error('Error cancelling Stripe subscription:', error);
        throw error;
      }),
    );
  }

  renewStripeSubscription() {
    const url = `${this.svcApiUrlA}payment/renew`;
    return this.http.post<{ success: boolean }>(url, {}).pipe(
      catchError(error => {
        this.logger.error('Error renewing Stripe subscription:', error);
        throw error;
      }),
    );
  }

  createShippingAgent(formData: ShippingAgentFormInput, confirmed: boolean) {
    const url = `${this.svcApiUrlA}vendors`;
    return this.http.post<CreateShippingAgentResponse>(url, { ...formData, confirmed }).pipe(
      catchError(error => {
        this.logger.error('Error creating shipping agent:', error);
        throw error;
      }),
    );
  }

  updateShippingAgent(shippingAgentId: number, formData: ShippingAgentFormInput) {
    const url = `${this.svcApiUrlA}vendors/${shippingAgentId}`;
    return this.http.put<UpdateShippingAgentResponse>(url, { ...formData }).pipe(
      catchError(error => {
        this.logger.error('Error creating shipping agent:', error);
        throw error;
      }),
    );
  }

  getShippingAgents() {
    const url = `${this.svcApiUrlA}vendors/shippingAgents`;
    return this.http.get<GetShippingAgentsResponse>(url).pipe(
      map(response => {
        return response.shippingAgents;
      }),
      catchError(error => {
        this.logger.error('Error getting shipping agents:', error);
        throw error;
      }),
    );
  }

  downloadTemplate(templateType: 'lcl' | 'fcl') {
    const url = `${this.svcApiUrlA}download/${templateType}_template`;
    return this.http.get<{ success: boolean; data: string }>(url).pipe(
      map(result => {
        return result.data;
      }),
      catchError(error => {
        this.logger.error(`Error getting shipping agents: ${error.message}`);
        throw error;
      }),
    );
  }
}
