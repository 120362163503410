// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LoggerConfig, NgxLoggerLevel } from 'ngx-logger';
import { version } from '../../package.json';
import { amplify } from './config/amplify.dev';

const appBaseUrl = 'https://dev.app.myvirtualff.com';
const apiBaseUrl = 'https://583bz8npbd.execute-api.ap-southeast-2.amazonaws.com/dev/';
const wsUrl = 'wss://k423g40j56.execute-api.ap-southeast-2.amazonaws.com/dev';
const acceptedFileFormat = '.pdf,.jpg,.jpeg,.png';

export const environment = {
  production: false,
  loggerConfig: {
    serverLoggingUrl: 'app/log',
    level: NgxLoggerLevel.TRACE,
    serverLogLevel: NgxLoggerLevel.FATAL,
    enableSourceMaps: true,
  } as LoggerConfig,
  appVersion: version + '-dev',
  amplify,
  appBaseUrl,
  apiBaseUrl,
  wsUrl,
  acceptedFileFormat,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
