import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorPage } from './pages/error/error.page';
import { AuthGuard } from './services/auth/auth.guard';
import { NoSubscriptionGuard } from './services/stripe/no-subscription.guard';
import { SubscriptionGuard } from './services/stripe/subscription.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
    // loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: 'quote/create',
    loadChildren: () =>
      import('./pages/create-or-edit-quote/create-or-edit-quote.module').then(m => m.CreateOrEditQuotePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quote/duplicate',
    loadChildren: () =>
      import('./pages/create-or-edit-quote/create-or-edit-quote.module').then(m => m.CreateOrEditQuotePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quote/edit',
    loadChildren: () =>
      import('./pages/create-or-edit-quote/create-or-edit-quote.module').then(m => m.CreateOrEditQuotePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quote-result',
    loadChildren: () => import('./pages/quote-result/quote-result.module').then(m => m.QuoteResultPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calculator-result',
    loadChildren: () =>
      import('./pages/calculator-result/calculator-result.module').then(m => m.CalculatorResultPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'price-on-application-request/:id',
    loadChildren: () =>
      import('./pages/price-on-application-request/price-on-application-request.module').then(
        m => m.PriceOnApplicationRequestPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'insert-rates',
    loadChildren: () => import('./pages/insert-rates/insert-rates.module').then(module => module.InsertRatesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'book-quote/:id',
    loadChildren: () => import('./pages/book-quote/book-quote.module').then(module => module.BookQuotePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./pages/suppliers/suppliers.module').then(m => m.SuppliersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-or-edit-supplier',
    loadChildren: () =>
      import('./pages/create-or-edit-supplier/create-or-edit-supplier.module').then(
        m => m.CreateOrEditSupplierPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-or-edit-supplier/:supplierId',
    loadChildren: () =>
      import('./pages/create-or-edit-supplier/create-or-edit-supplier.module').then(
        m => m.CreateOrEditSupplierPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'job/:jobId',
    loadChildren: () => import('./pages/job/job.module').then(m => m.JobPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'job-list',
    loadChildren: () => import('./pages/job-list/job-list.module').then(m => m.JobListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quote-list-all',
    loadChildren: () =>
      import('./pages/quote-list-all/quote-list-all/quote-list-all.module').then(m => m.QuoteListAllPageModule),
    canActivate: [AuthGuard, SubscriptionGuard],
  },
  {
    path: 'email-template/:jobId',
    loadChildren: () => import('./pages/email-template/email-template.module').then(m => m.EmailTemplatePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'conversations/:jobId',
    loadChildren: () => import('./pages/conversations/conversations.module').then(m => m.ConversationsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'conversation-preview/:jobId/:conversationId',
    loadChildren: () =>
      import('./pages/conversation-preview/conversation-preview.module').then(m => m.ConversationPreviewPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'booking-confirmation/:jobId',
    loadChildren: () =>
      import('./pages/booking-confirmation/booking-confirmation.module').then(m => m.BookingConfirmationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule),
    canActivate: [AuthGuard, NoSubscriptionGuard],
  },
  {
    path: '**',
    component: ErrorPage,
    loadChildren: () => import('./pages/error/error.module').then(module => module.ErrorPageModule),
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
