import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { isNil, pick } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AuthStore } from './auth.store';

export interface UserDetail {
  userName: string;
  email: string;
  givenName: string;
  familyName: string;
  companyName?: string;
  companyAbn?: string;
  supplierId?: number;

  // from api
  appEmail: string;
  id: number;
  phone: string;
  status: string;
}

export type IUpdateUserDetail = Omit<UserDetail, 'email' | 'id' | 'appEmail' | 'phone' | 'status'>;
export type IUpdateUserDetailApi = Omit<
  UserDetail,
  'companyName' | 'companyAbn' | 'supplierId' | 'userName' | 'id' | 'appEmail' | 'phone' | 'status'
>;

export interface ProfileInfo {
  supplier: { id: number; name: string };
  commodity: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly loggedIn$: Observable<boolean>;
  public readonly user$: Observable<any>;
  private readonly _loggedIn: BehaviorSubject<boolean>;
  private readonly _user: BehaviorSubject<any>;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private api: ApiService,
    private authStore: AuthStore,
  ) {
    this._loggedIn = new BehaviorSubject<boolean>(false);
    this.loggedIn$ = this._loggedIn.asObservable();
    this._user = new BehaviorSubject<any>(null);
    this.user$ = this._user.asObservable();
  }

  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map(user => {
        this._loggedIn.next(true);
        this._user.next(user);
        return true;
      }),
      catchError(err => {
        console.warn(err);
        this._loggedIn.next(false);
        return of(false);
      }),
    );
  }

  logout() {
    return from(Auth.signOut()).pipe(
      catchError(err => {
        throw err;
      }),
    );
  }

  async changePassword(data: { oldPassword: string; newPassword: string }) {
    const { oldPassword, newPassword } = data;
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  async updateUserDetail(userDetail: IUpdateUserDetail) {
    // update cognito attributes
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
      given_name: userDetail.givenName,
      family_name: userDetail.familyName,
      'custom:supplierId': userDetail.supplierId,
      'custom:companyName': userDetail.companyName,
      'custom:companyAbn': userDetail.companyAbn,
    });

    // update the backend api
    const userEmail = user.attributes.email;
    await this.api.updateUserDetail({ email: userEmail, ...pick(userDetail, ['givenName', 'familyName']) }).toPromise();

    return result;
  }

  async getUserDetail(): Promise<UserDetail> {
    const user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(user);

    this.logger.debug('Current user: ', user, attributes);

    const { email, family_name: familyName, given_name: givenName } = user.attributes ?? {};

    const companyName = user.attributes?.['custom:companyName'];
    const companyAbn = user.attributes?.['custom:companyAbn'];
    const supplierId = user.attributes?.['custom:supplierId'];

    const userDetailApi = await this.api.getUserDetail().toPromise();

    const userDetail = {
      userName: user.username,
      email,
      givenName,
      familyName,
      companyName,
      companyAbn,
      supplierId,
      appEmail: userDetailApi.appEmail,
      id: userDetailApi.id,
      phone: userDetailApi.phone,
      status: userDetailApi.status,
    } as UserDetail;

    this.authStore.update({ userDetail });

    return userDetail;
  }

  public getCognitoGroups(): Observable<string[]> {
    return from(Auth.currentSession()).pipe(
      map(session => session.getAccessToken().payload['cognito:groups']),
      catchError(() => of([])),
    );
  }

  async getOrSetUserStatus(): Promise<{ isAdmin: boolean; isVendor: boolean }> {
    const { isAdmin, isVendor } = this.authStore.getValue();
    if (isNil(isAdmin) || isNil(isVendor)) {
      const userStatus = await this.api.getUserStatus().toPromise();
      this.authStore.update({ isAdmin: userStatus.isAdmin ?? false, isVendor: userStatus.isVendor ?? false });
      return userStatus;
    }
    return { isAdmin, isVendor };
  }

  unsetUserStatus() {
    this.authStore.update({ isAdmin: null, isVendor: null });
  }

  async signOut() {
    await Auth.signOut();
    this._loggedIn.next(false);
    this.router.navigate(['/auth']);
  }
}
