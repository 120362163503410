import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UserDetail } from './auth.service';

export interface IAuthStore {
  userDetail: UserDetail;
  isAdmin: boolean | null;
  isVendor: boolean | null;
}

function createInitialState(): IAuthStore {
  return {
    userDetail: null,
    isAdmin: null,
    isVendor: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<IAuthStore> {
  constructor() {
    super(createInitialState());
  }
}
